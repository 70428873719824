<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BUSINESS PERMIT (RETURN FUND)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="year_of"
              class="mx-2"
              dense
              outlined
              label="Year of"
              :items="year_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="branch_id"
              class="mx-2"
              dense
              outlined
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="selected_branch"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="fund_id"
              class="mx-2"
              dense
              outlined
              label="Fund ID"
              :items="fund_items"
              item-value="id"
              item-text="fund_counter"
              :rules="rules.combobox_rule"
              @change="selected_fund"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="fund_balance"
              label="Fund Balance"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="bank_id"
              class="mx-2"
              dense
              outlined
              label="Bank"
              :items="bank_items"
              item-value="id"
              item-text="bank_code"
              :rules="rules.combobox_rule"
              @change="selected_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="account_no"
              label="Account #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date"
              label="Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            class="w-full"
            color="primary"
            @click="save_request"
            v-if="!saving_data"
          >
            Save Request
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.fund_counter }}
            </td>
            <td>
              {{ item.bank_code }}
            </td>
            <td>
              {{ item.amount }}
            </td>
            <td>
              <v-icon
                class="mr-2"
                color="error"
                @click="delete_liquidation(item)"
              >
                {{icons.mdiDelete}}
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiDelete, mdiAlertOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Fund Counter', value: 'tin', sortable: false},
        {text: 'Bank', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      data_items: [],
      fund_items: [],
      branch_items: [],
      category_items: [],
      year_items: [],
      bank_items: [],
      saving_data: false,
      alert: false,
      alert_message: '',
      account_no: '',
      amount: '',
      date: '',
      bank_id: '',
      fund_balance: '',
      fund_id: '',
      branch_id: '',
      year_of: '',
      category_id: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiDelete,
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('business_permit_return_fund', ['register_business_permit_return_fund', 'delete_business_permit_return_fund']),
      ...mapActions('business_permit_released_fund', ['initialize_business_permit_liquidation', 'list_of_category_have_released_fund', 'list_of_branches_have_released_fund', 'list_of_released_fund_on_branches',]),
      ...mapActions('deposit_slip_receivable', ['initialize_deposit_slip_receivable']),
      initialize_data() {
        this.initialize_business_permit_liquidation()
          .then(response => {
            this.year_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.initialize_deposit_slip_receivable()
          .then(response => {
            this.bank_items = response.data[0].bank
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_year() {
        this.fund_id = ''
        this.fund_balance = ''
        this.fund_items = []
        this.list_of_category_have_released_fund({
          year: this.year_of,
        })
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        this.fund_id = ''
        this.fund_balance = ''
        this.fund_items = []
        this.list_of_branches_have_released_fund({
          year: this.year_of,
          category_id: this.category_id,
        })
          .then(response => {
            this.branch_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_branch() {
        this.fund_id = ''
        this.fund_balance = ''
        this.list_of_released_fund_on_branches({
          year: this.year_of,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.fund_items = response.data[0].fund_data
            this.data_items = response.data[0].fund_return
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_fund() {
        var index = this.fund_items.map(function (x) {
          return x.id;
        }).indexOf(this.fund_id)
        if (index != -1) {
          this.fund_balance = this.formatPrice(this.fund_items[index].balance)
        }
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('business_permit_fund_id', this.fund_id);
          data.append('particulars', this.particulars);
          data.append('bank_id', this.bank_id);
          data.append('date', this.date);
          data.append('amount', this.amount);
          this.register_business_permit_return_fund(data)
            .then(() => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: 'SUCCESSFULLY SAVE THE LIQUIDATION',
              })
              this.selected_branch()
              this.fund_id = ''
              this.fund_balance = ''
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.account_no = this.bank_items[index].account_no
        }
      },
      delete_liquidation(value) {
        const data = new FormData()
        data.append('id', value.id);
        this.delete_business_permit_return_fund(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: 'DELETE SUCCESSFULLY',
            })
            this.selected_branch()
            this.fund_id = ''
            this.fund_balance = ''
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          });
      }
    },
  }
</script>
